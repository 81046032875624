<template>
  <div class="examination-management">
    <div class="examination-header">
      <el-button type="primary" @click="createExamination">创建考场</el-button>
    </div>
    <el-table
      class="customTable"
      :data="tableData"
      style="width: 100%; margin-top: 20px; flex: 1"
      height="1%"
      size="medium"
      :header-cell-style="{
        fontWeight: 'normal',
        height: '60px',
        color: '#666666',
        background: '#F6F6F6',
        fontSize: '16px',
      }"
      :cell-style="{ fontSize: '16px', color: '#333333', height: '70px' }"
    >
      <el-table-column prop="tid" label="序号" align="center" width="180">
      </el-table-column>
      <el-table-column
        prop="examination_room_name"
        label="考场名称"
        align="center"
        width="180"
      >
      </el-table-column>
      <el-table-column
        prop="examination_room_address"
        label="考场地点"
        align="center"
      >
      </el-table-column>
      <!-- <el-table-column
        prop="address"
        label="考场考试时间"
        width="390px"
        align="center"
      >
        <template slot-scope="scope">
          <span
            >{{ scope.row.examination_start_time }} ~
            {{ scope.row.examination_end_time }}</span
          >
        </template>
      </el-table-column> -->
      <el-table-column prop="apply_user" label="联系人" align="center">
      </el-table-column>
      <el-table-column prop="apply_mobile" label="手机号" align="center">
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-link
            type="success"
            :underline="false"
            @click="editExamination(scope.row)"
            >编辑</el-link
          >
          <el-link
            type="danger"
            :underline="false"
            @click="deleteExamination(scope.row)"
            >删除</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="dialogTitle"
      custom-class="green-dialog"
      :visible.sync="examinationVisible"
      @close="resetForm"
      @opened="openExamination"
      width="500px"
    >
      <el-form :model="form" ref="form" :rules="examinationRules">
        <el-form-item label="考场名称" prop="name">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="考场地点" prop="location">
          <el-input v-model="form.location" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="考场考试时间" style="width: 130px" prop="timeDate">
          <el-date-picker
            v-model="form.timeDate"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 450px"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item> -->
         <el-form-item label="考试级别" prop="examLevel">
            <el-select
              v-model="form.examLevel"
              placeholder="请选择"
              style="width: 450px"
            >
              <el-option
                v-for="item in level"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        <el-form-item label="申请人" prop="applyname">
          <el-input
            v-model="form.applyname"
            autocomplete="off"
          ></el-input> </el-form-item
        ><el-form-item label="申请人电话" prop="applytel">
          <el-input
            v-model="form.applytel"
            autocomplete="off"
            onkeyup="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button @click="examinationVisible = false">取 消</el-button>
        <el-button
          style="margin-left: 30px"
          type="primary"
          @click="confirmForm('form')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-pagination
      class="pager-center"
      style="margin: 20px 0 30px; text-align: center"
      :current-page="examinationPages.currentPageNum"
      :page-size="examinationPages.eachPageNum"
      :total="examinationPages.total"
      layout="prev, pager, next, jumper"
      @current-change="CurrentChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { addExam, showExam, delExam, lookExam, updateExam } from "@/utils/apis";

export default {
  name: "Index",
  data() {
    return {
      examinationVisible: false,
      dialogTitle: "",
      level:[
        {
          value: 1,
          label: "3级",
        },
        {
          value: 2,
          label: "4级",
        },
      ],
      examinationPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      form: {
        id: "",
        name: "",
        location: "",
        // level:'',
        // timeDate: [],
        examLevel:'',
        applyname: "",
        applytel: "",
      },
      tableData: [],
      examinationRules: {
        name: [
          { required: true, message: "请输入考场名称", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
        location: [
          { required: true, message: "请输入考场地点", trigger: "blur" },
          {
            min: 3,
            max: 50,
            message: "长度在 3 到 50 个字符",
            trigger: "blur",
          },
        ],
        level: [
          {
            required: true,
            message: "请选择考试级别",
            trigger: "change",
          },
        ],
        applyname: [
          { required: true, message: "请输入申请人", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "长度在 2 到 10 个字符",
            trigger: "blur",
          },
        ],
        applytel: [
          { required: true, message: "请输入申请人电话", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度在 11 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.showExaminationListData();
  },
  methods: {
    // 初始化考场列表数据
    showExaminationListData() {
      let params = {
        page: this.examinationPages.currentPageNum,
        limit: this.examinationPages.eachPageNum,
      };
      showExam(params).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.list;
          let tindex = 0;
          this.tableData.map((item) => {
            item.tid = tindex += 1;
            return item;
          });
          this.examinationPages.total = res.data.total;
        }
      });
    },
    // 显示新建考场会话框
    createExamination() {
      this.dialogTitle = "创建考场";
      this.examinationVisible = true;
    },
    // 显示编辑考场会话框
    editExamination(row) {
      this.form.id = row.id;
      this.dialogTitle = "编辑考场";
      this.examinationVisible = true;
    },
    // 关闭
    resetForm() {
      this.$refs.form.resetFields();
      this.form.id = "";
      this.form.applytel = "";
    },
    // 提交
    confirmForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            examination_room_name: this.form.name,
            examination_room_address: this.form.location,
            examination_category_id:this.form.examLevel,
            // examination_start_time: JSON.stringify(
            //   new Date(this.form.timeDate[0]).getTime()
            // ).substring(0, 10),
            // examination_end_time: JSON.stringify(
            //   new Date(this.form.timeDate[1]).getTime()
            // ).substring(0, 10),
            apply_user: this.form.applyname,
            apply_mobile: this.form.applytel,
          };
          if (this.form.id) {
            params.id = this.form.id;
            updateExam(params)
              .then((res) => {
                if (res.code === 200) {
                  this.$message({
                    message: res.msg,
                    type: "success",
                  });
                  this.examinationVisible = false;
                  this.showExaminationListData();
                }
              })
              .catch((err) => {
                console.log("err", err);
              });
          } else {
            addExam(params)
              .then((res) => {
                if (res.code === 200) {
                  this.$message({
                    message: res.msg,
                    type: "success",
                  });
                  this.examinationVisible = false;
                  this.showExaminationListData();
                }
              })
              .catch((err) => {
                console.log("err", err);
              });
          }
        } else {
          return false;
        }
      });
    },
    openExamination() {
      if (this.form.id) {
        let params = {
          id: this.form.id,
        };
        lookExam(params).then((res) => {
          console.log(res)
          this.form.name = res.data.examination_room_name;
          this.form.location = res.data.examination_room_address;
          this.form.examLevel=res.data.examination_category_id
          // this.form.timeDate.push(
          //   res.data.examination_start_time,
          //   res.data.examination_end_time
          // );
          this.form.applyname = res.data.apply_user;
          this.form.applytel = res.data.apply_mobile;
        });
      }
    },

    deleteExamination(row) {
      this.$confirm(`是否删除该条考场信息，删除将无法恢复`, "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        customClass: "green-message-box",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: row.id,
          };
          delExam(params).then((res) => {
            if (res.code === 200) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.showExaminationListData();
              this.$forceUpdate();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    CurrentChange(val) {
      this.examinationPages.currentPageNum = val;
      this.showExaminationListData();
    },
  },
};
</script>

<style lang="scss" scoped>
.examination-management {
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .examination-header {
    display: flex;
    justify-content: flex-end;
  }
}
</style>